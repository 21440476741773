@tailwind base;
@tailwind components;
@tailwind utilities;

.popup {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-10 rounded shadow-lg max-h-[90vh] overflow-scroll w-[500px] max-md:w-[90vw];
}

.popup_bg {
    @apply fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50;
}

.closer {
    @apply absolute top-3 right-3;
}

@layer base {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ccc;
}
body * {
    font-family: 'Open Sans', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#logo {
    width: 200px;
    margin: 0 auto;
    display: block;
}

.previewScreen {
    display: inline-block;
    float: right;
    width: 60vw;
    height: 88vh;
    margin: 8vh 2vw 2vh 2vw;
    border: 1px solid #999;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #888;
    box-sizing: border-box;
    clear: none;
}

configFieldsWrapper {
    float: left;
    height: 100vh;
    box-sizing: border-box;
    overflow: scroll;
    padding: 80px 10px 30px 0;
}

h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}

main .rounded h2,
main .rounded-lg h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    background-color: #ec6906;
    color: white;
}

configFieldsWrapper label {
    float: left;
    width: 30%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
    padding-right: 10px;
    font-size: 14px;
}

configFieldsWrapper input,
configFieldsWrapper select,
configFieldsWrapper textarea,
configFieldsWrapper .radioGroup div,
configFieldsWrapper .dropzone {
    clear: right;
    display: inline-block;
    margin-bottom: 5px;
    padding: 5px 10px;
    text-align: left;
    border-radius: 3px;
    outline: none;
    border: 1px solid #aaa;
    width: 68%;
    font-size: 14px;
}

configFieldsWrapper textarea {
    min-height: 100px;
}

configFieldsWrapper .dropzone {
    background-color: #999999;
    color: white;
    font-size: 14px;
    text-align: center;
    aspect-ratio: 3 / 2;
    background-repeat: no-repeat;
    background-size: cover;
}

configFieldsWrapper .radioGroup div {
    border: 0px;
    margin: 0 !important;
    padding-right: 0 !important;
    float: left !important;
    display: block;
    width: auto;
    margin-right: 10px;
}

configFieldsWrapper .radioGroup {
    display: block;
    overflow: hidden;
}

configFieldsWrapper .radioGroup label {
    text-align: left;
    padding: 0;
    margin: 0;
}

configFieldsWrapper .radioGroup div input {
    float: left;
    width: auto;
    margin: 0 5px 0 0;
    padding: 0;
    position: relative;
    top: 4px;
}

input.third,
textarea.third,
select.third {
    width: 25%;
    clear: none !important;
    display: inline-block;
    margin-right: 10px;
}

companyDetails {
    display: block;
    width: 33vw;
    min-height: 34vh;
    margin: 2vw 0 2vw 2vw;
    box-sizing: border-box;
    clear: none;
    box-shadow: 2px 2px 10px #888;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #999;
    padding: 20px;
}

rooms {
    display: block;
    width: 33vw;
    min-height: 34vh;
    margin: 2vw 0 2vw 2vw;
    box-sizing: border-box;
    clear: none;
    box-shadow: 2px 2px 10px #888;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #999;
    padding: 20px;
}

input[type='button'] {
    background-color: #ff7700;
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    float: right;
    clear: both;
}

roomRule {
    display: block;
    background-color: #f3f3f3;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 3px;
    color: black;
    cursor: pointer;
}

roomRule:hover {
    background-color: #ddd;
}

roomRule[status='checked'] status,
status.checked {
    background-color: yellow;
    color: white;
    width: 15px;
    height: 15px;
    display: inline-block;
    float: left;
    margin-right: 10px;
    border-radius: 20px;
    margin-top: 3px;
}

roomRule[status='confirmed'] status,
status.confirmed {
    background-color: darkorange;
    color: white;
    width: 15px;
    height: 15px;
    display: inline-block;
    float: left;
    margin-right: 10px;
    border-radius: 20px;
    margin-top: 3px;
}

roomRule[status='published'] status,
status.published {
    background-color: green;
    color: white;
    width: 15px;
    height: 15px;
    display: inline-block;
    float: left;
    margin-right: 10px;
    border-radius: 20px;
    margin-top: 3px;
}

roomRule[status='remove'] status,
status.remove {
    background-color: red;
}

roomRule status,
status {
    background-color: grey;
    color: white;
    width: 15px;
    height: 15px;
    display: inline-block;
    float: left;
    margin-right: 10px;
    border-radius: 20px;
    margin-top: 3px;
    box-shadow: 1px 1px 5px #888;
}

roomRule title {
    display: block;
    font-size: 15px;
}

roomRule delete,
roomRule copy {
    float: right;
    color: #888888;
    margin-left: 5px;
}

popup backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    width: 37vw;
    height: 100vh;
    z-index: 999;
}

popup inner {
    display: block;
    position: fixed;
    padding: 25px;
    margin: 5vh 3vw;
    top: 0;
    left: 0;
    width: 30vw;
    height: 90vh;
    z-index: 99999;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 15px #000;
    color: black;
    overflow: scroll;
}

popup inner .column {
    width: 50%;
}

.toggleGroup {
    display: block;
    overflow: hidden;
}

.toggleGroup input {
    display: none;
}

.toggleGroup div {
    display: inline-block;
    padding: 3px 7px;
    border-radius: 15px;
    border: 1px solid #999;
    color: #999999;
    margin: 0 3px 3px 0;
    font-size: 13px;
    cursor: pointer;
}

.toggleGroup div.active {
    background-color: #ec6906;
    color: white;
    border: 1px solid #ec6906;
}

popup inner .warning {
    background-color: #ffcc00;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
}

#login {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #999999;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
}

#loginForm {
    display: block;
    width: 500px;
    height: auto;
    background-color: white;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-top: -30vh;
    z-index: 9999999;
    border-radius: 5px;
    box-shadow: 2px 2px 15px #555;
    padding: 20px;
    text-align: center;
}

.mb-4 {
    display: block;
    text-align: left;
}

.mb-4 input {
    display: inline-block;
    width: 40%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #999;
    margin-bottom: 10px;
}

.mb-4 label {
    width: 40%;
    float: left;
    text-align: right;
    margin-right: 10px;
}

.day {
    width: 9%;
    /*float:left;*/
    padding: 5px 0;
    text-align: center;
    font-size: 10px;
    margin: 0 1px 6px 0;
}

.circle {
    display: inline-block;
    padding: 5px;
    border: 1px solid black;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    text-align: center;
}

tr[status='remove'] {
    opacity: 0.2;
}

.rooms {
    margin: 0px auto 0 auto;
    padding-bottom: 50px;
    overflow: hidden;
    text-align: center;
}

.partner .rooms {
    margin: 0;
}

.partner .rooms .room {
    margin: 5px 10px;
}

.room {
    display: inline-block;
    width: 250px;
    min-height: 250px;
    margin: 0 1vw 4vh 1vw;
    box-shadow: 1px 1px 5px #888;
    border-radius: 8px;
    background-color: white;
    overflow: hidden;
    vertical-align: top;
    cursor: pointer;
}

/*max screen width 600*/
@media screen and (max-width: 600px) {
    .room {
        zoom: 0.8;
    }
}

.room[opacity='0.5'] {
    opacity: 0.5;
}

.room:hover {
    box-shadow: 0px 0px 3px #000;
}

.room .image {
    width: 100%;
    height: 166px;
    background-size: cover;
    background-position: center;
    background-color: #aaaaaa;
}

.room .title {
    font-size: 15px;
    display: block;
    padding: 7px 10px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    text-align: left;
}

.room .description {
    padding: 0 10px;
    display: inline-block;
    overflow: hidden;
    max-lines: 4;
    text-overflow: ellipsis;
    box-sizing: content-box;
    font-size: 13px;
    line-height: 1.4em;
    opacity: 0.6;
    height: 5.8em;
    margin-bottom: 10px;
    text-align: left;
}

.languageSwitcher {
    margin-left: 30%;
    margin-bottom: 4px;
}

.languageSwitcher img {
    height: 17px;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
}

.languageSwitcher img:hover {
    box-shadow: 0px 0px 3px #000;
}

.translate {
    margin-left: 30%;
    text-decoration: underline;
}

.btn.primary {
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    color: #fff;
    background-color: #ec6906;
}

/*CSS for phone screens*/
@media only screen and (max-width: 600px) {
    .mb-4 label {
        display: none;
    }

    .mb-4 input {
        width: 100%;
    }
}
